import PropTypes from "prop-types";
import { isHomePageUrl } from "SourceUtil/Url";
import BrowserDatabase from "SourceUtil/BrowserDatabase";

class MetaPlugin {
  mapStateToProps(args, callback, instance) {
    const { MetaReducer, ConfigReducer } = args[0];

    return {
      ...callback.apply(instance, args),
      redirect_from: MetaReducer.redirect_from,
      redirect_code_previous: MetaReducer.redirect_code_previous,
      store_code : ConfigReducer.code,
    };
  }

  propTypes(args, callback, instance) {
    return {
      args,
      redirect_from: PropTypes.string,
      redirect_code_previous: PropTypes.string,
    };
  }

  containerProps(args, callback, instance) {
    const {
      redirect_from = "",
      redirect_code_previous = "",
      status_code,
      store_code
    } = instance.props;
    
    const data = {
      ...callback(...args),
      storeCode: store_code,
      redirect_from,
      redirect_code_previous: status_code
        ? status_code
        : redirect_code_previous,
    };

    return data;
  }

  renderPreRenderTags(props) {
    const { redirect_from, redirect_code_previous } = props;
    const url = redirect_from
      ? window.location.origin + redirect_from
      : window.location.href;

    if (redirect_code_previous === "404") {
      return (
        <>
          <meta name="render:status_code" content={redirect_code_previous} />
          <meta name="prerender-status-code" content={redirect_code_previous} />
        </>
      );
    }

    if (redirect_code_previous && url) {
      return (
        <>
          <meta name="render:status_code" content={redirect_code_previous} />
          <meta name="render:header" content={`Location: ${url}`} />
          <meta name="prerender-status-code" content={redirect_code_previous} />
          <meta name="prerender-header" content={`Location: ${url}`} />
        </>
      );
    }

    return null;
  }

  renderCanonical(args, callback, instance) {
    let { canonical_url, storeCode, redirect_code_previous } = instance.props;
    const { pathname, origin } = location;
    // // Check if canonical_url is provided
    if (!canonical_url && redirect_code_previous !== '404') {
        // Append storeCode to the pathname if it doesn't already start with storeCode
        if (!pathname.startsWith(`/${storeCode}`)) {
            canonical_url = `${origin}/${storeCode}${pathname}`;
        } else {
            canonical_url = `${origin}${pathname}`;
        }
    }

    if (
      !canonical_url.endsWith(".html") &&
      canonical_url.endsWith("/") &&
      !isHomePageUrl(pathname)
    ) {
      canonical_url = canonical_url.slice(0, -1);
    }

    if (!canonical_url.endsWith("/") && isHomePageUrl(pathname)) {
      canonical_url += "/";
    }

    if(canonical_url && 
      !canonical_url.includes(storeCode) && 
      !canonical_url.endsWith("/") && 
      !canonical_url.endsWith(".html")) {
    // Extract the base URL (protocol + host)
    const url = new URL(canonical_url);
    const baseUrl = `${url.protocol}//${url.host}`;
    const path = url.pathname;

        // Construct the new URL with the storeCode inserted
        canonical_url = `${baseUrl}/${storeCode}${path}`;
    }

    if(!canonical_url) {
        return null;
    }

    return <link rel="canonical" href={canonical_url} />;
  }

  renderMeta(args, callback, instance) {
    return (
      <>
        {callback.apply(instance, args)}
        {renderPreRenderTags(instance.props)}
      </>
    );
  }

  // Omit null content item
  _getMetadata(args, callback, instance) {
    let result = callback.apply(instance, args);
    return result.reduce((init, item) => {
      if (item.content) {
        init.push(item);
      }
      return init;
    }, []);
  }
}

const {
  propTypes,
  containerProps,
  renderMeta,
  renderPreRenderTags,
  mapStateToProps,
  renderCanonical,
  _getMetadata,
} = new MetaPlugin();

export default {
  "Component/Meta/Container/mapStateToProps": {
    function: [
      {
        position: 101,
        implementation: mapStateToProps,
      },
    ],
  },
  "Component/Meta/Component": {
    "member-function": {
      renderMeta,
      renderPreRenderTags,
      renderCanonical,
    },
  },
  "Component/Meta/Container": {
    "member-function": {
      containerProps,
      _getMetadata,
    },
    "static-member": {
      propTypes,
    },
  },
};
