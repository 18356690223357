import { getQueryParam, setQueryParams } from 'Util/Url';

const _getPageFromUrl = (_instance, url) => {
    const { location: currentLocation } = _instance.props;
    const location = url || currentLocation;

    return +(getQueryParam('page', location || '') || 1);
};

const _setLoadMoreParam = (instance) => {
    const { location, history } = instance.props;
    setQueryParams({ loadmore: true }, location, history);
};

const _getLoadMoreParam = (instance) => {
    const { location, device } = instance.props;
    if(!device.isMobile)
        return null;

    return getQueryParam('loadmore', location);
};

const componentDidUpdate = (_args, _callback, _instance) => {
    const [prevProps] = _args;
    const {
        location: prevLocation
    } = prevProps;
    const prevPage = _getPageFromUrl(_instance, prevLocation);
    const currentPage = _instance._getPageFromUrl();
    _callback.apply(_instance, _args);
    if (
        currentPage !== prevPage
        // for stopping extra request while load more is pressed in mobile
        && !_getLoadMoreParam(_instance)
    ) {
        _instance.requestPage(_instance._getPageFromUrl());
    }
};

export const updatePage = (_args, _callback, _instance) => {
    const [, isLoadMore] = _args;
    // add load more in query to identify and stop extra request fetching product
    if (isLoadMore) {
        _setLoadMoreParam(_instance);
    }

    return _callback.apply(_instance, _args);
};

export default {
    'Component/ProductList/Container': {
        'member-function': {
            componentDidUpdate,
            updatePage
        }
    }
};
