/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ExpandableContent from 'Component/ExpandableContent';
import RangeSelector from 'Component/RangeSelector';

/** @namespace Component/CategoryPriceRange/Component */
export class CategoryPriceRange extends PureComponent {
    static propTypes = {
        minPriceValue: PropTypes.number.isRequired,
        maxPriceValue: PropTypes.number.isRequired,
        priceValue: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number
        }).isRequired,
        updatePriceRange: PropTypes.func.isRequired
    };

    state = {
        min_price : 0,
        max_price : 10000
    }

    componentDidMount() {
        this.updateRangeState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { options : { attribute_values : prevValues } } = prevProps;
        let { options : { attribute_values  } } = this.props;
        if(JSON.stringify(prevValues) !== JSON.stringify(attribute_values)){
            this.updateRangeState();
        }
    }

    updateRangeState(){
        const { options } = this.props;
        let minPriceValue = options.attribute_values[0];
        let maxPriceValue = options.attribute_values[options.attribute_values.length - 1];
        this.setState({
            min_price : minPriceValue,
            max_price :maxPriceValue
        });
    }

    render() {
        const {
            updatePriceRange,
            priceValue,
            minPriceValue,
            maxPriceValue,
            current_currency
        } = this.props;

        const { min: minValue, max: maxValue } = priceValue;
        const min = minValue || minPriceValue;
        const max = maxValue || maxPriceValue;
        const minPrice = parseInt(this.state.min_price);
        const maxPrice = parseInt(this.state.max_price);

        if (maxPriceValue - minPriceValue === 0) {
            return null;
        }

        return (
            <ExpandableContent
              key="price"
              heading={ __('Price') }
              isCategoryPage={ true }
              priceRange={ true }
              isContentExpanded={ true }
              // subHeading={ __('From: %s to %s', this.state.min_price, this.state.max_price) }
              mix={ {
                  block: 'CategoryFilterOverlay',
                  elem: 'Filter filters__section',
                  mods: { type: 'price' }
              } }
            >
                <RangeSelector
                  value={ priceValue }
                  current_currency = { current_currency }
                  minValue={ minPrice }
                  maxValue={ maxPrice + 1  }
                  onChangeComplete={ updatePriceRange }
                />
            </ExpandableContent>
        );
    }
}

export default CategoryPriceRange;
