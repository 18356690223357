import CategoryPaginationLink from 'Component/CategoryPaginationLink';

const renderPageLink = (args, callback, instance) => {
    const {isCategory} = instance.props;
    if (isCategory) {
        const [pageNumber, label, children, isCurrent] = args;
        const {
            pathname, getSearchQuery
        } = instance.props;
        return (<li
                key={pageNumber}
                block="CategoryPagination"
                elem="ListItem"
            >
                <CategoryPaginationLink
                    label={label}
                    url_path={pathname}
                    getPage={()=>{}}
                    isCurrent={isCurrent}
                    pageNumber={pageNumber}
                    getSearchQueryForPage={getSearchQuery}
                >
                    {children}
                </CategoryPaginationLink>
            </li>);
    }
    return callback.apply(instance, args);
}
export default {
    'Component/CategoryPagination/Component': {
        'member-function': {
            renderPageLink
        }
    }
};