import { getIndexedProduct, CONFIGURABLE } from 'Util/Product';
import { extendCartDataProducts } from 'Util/Product/PerformaceProduct.js'
import { BUNDLER_PRODUCTS } from '../component/BundlerSection/BundlerSection.conf';

export const _processResponse = (args, callback, instance) => {
    let data = args[0];
    let product_links = args[1];

    if (data?.products?.items?.length > 0) {
        data.products.items = extendCartDataProducts(data.products.items, true) || {};
    }

    const { products: { items } } = data;

    const indexedBySku = items.reduce((acc, item) => {
        const { sku } = item;
        acc[sku] = getIndexedProduct(item);

        return acc;
    }, {});

    const linkedProducts = product_links.reduce((acc, link) => {
        const { linked_product_sku, link_type, discount, discount_type, parent_products, applied_products } = link;

        if (link_type !== BUNDLER_PRODUCTS && indexedBySku[linked_product_sku] && acc[link_type]) {
            acc[link_type].items.push(
                {
                    ...indexedBySku[linked_product_sku],
                    discount,
                    discount_type
                }
            );

            acc[link_type].total_count++;

            return acc;
        }

        const indexedItem = { ...indexedBySku[parent_products[0].sku] };

        if (
            link_type === BUNDLER_PRODUCTS &&
            parent_products.length > 0 &&
            parent_products[0] &&
            parent_products[0].sku &&
            indexedItem &&
            acc[link_type]
        ) {
            indexedItem.discount = discount;
            indexedItem.discount_type = discount_type;
            indexedItem.applied_products = applied_products;
            indexedItem.linked_product_sku = linked_product_sku;
            indexedItem.parent_products_sku = parent_products[0].sku;
            indexedItem.isConfigurableBundler = indexedItem.type_id === CONFIGURABLE && linked_product_sku === parent_products[0].sku;
            acc[link_type].items.push(indexedItem);

            acc[link_type].total_count++; 1
        }

        return acc;
    }, {
        upsell: { total_count: 0, items: [] },
        related: { total_count: 0, items: [] },
        crosssell: { total_count: 0, items: [] },
        bundler: { total_count: 0, items: [] },
        associated: { total_count: 0, items: [] }
    });

    return linkedProducts;

}
export default {
    'Store/LinkedProducts/Dispatcher': {
        'member-function': {
            '_processResponse': _processResponse
        }
    }
};
