import ProductListQuery from 'Query/ProductList.query';
const mapStateToProps =  (args, callback, instance) => {
    const [state] = args;
    let seo_default_sort_direction = [];
    if (state.ConfigReducer.seo_default_sort_direction) {
        seo_default_sort_direction = JSON.parse(state.ConfigReducer.seo_default_sort_direction).reduce((collector, option) => {
            collector[option.attribute] = option.direction;
            return collector;
        }, {});
    }
    return {
        ...callback.apply(instance, args),
        seo_default_sort_direction,
        default_sort_by: state.ConfigReducer.category_default_sort_by
    };
};

const requestProductList = (args, callback, instance) => {
    let [ options ] = args;
    const { isNext } = options;
    let { seo_default_sort_direction, default_sort_by, stepsSkus, isBrandSteps, defaultWidgetSorting, defaultWidgetSortingDir } = instance.props;
    if(defaultWidgetSorting)
        default_sort_by = defaultWidgetSorting;
    if(isBrandSteps && stepsSkus){
        const { conditions, ...newFilters } = options.args.filter;
        options.args.pageSize = 300;
        options.args.filter = newFilters;
        options.args.filter.productsSkuArray = stepsSkus.split(',');
    }

    if (!isNext) {
        instance.updateLoadStatus(true);
    }
    if(default_sort_by && seo_default_sort_direction[default_sort_by]){
        options.args.sort = {
            sortKey : default_sort_by,
            sortDirection : defaultWidgetSortingDir ? defaultWidgetSortingDir : seo_default_sort_direction[default_sort_by]
        }
    }

    instance.fetchData(
        [ProductListQuery.getQuery(options)],
        isNext ? instance.appendPage : instance.updateProductListItems,
        instance.onError
    );
}
export const config = {
    'Component/ProductListWidget/Container': {
        'member-function': {
            requestProductList: [
                {
                    position:1,
                    implementation: requestProductList
                }
            ]
        }
    },
    'Component/ProductListWidget/Container/mapStateToProps': {
        function: [
            {
                position: 100,
                implementation: mapStateToProps
            }
        ]
    }
};

export default config;
