import { PureComponent } from 'react';
import { connect } from "react-redux";
import BundlerSectionComponent from './BundlerSection.component';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    BUNDLE,
    CONFIGURABLE,
    GROUPED,
    SIMPLE
} from 'Util/Product';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace Component/BundlerSection/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    configurableProduct: state.ProductReducer.product,
    bundlerItems: state.LinkedProductsReducer.linkedProducts.bundler
});
/** @namespace Component/BundlerSection/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    addProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.addProductToCart(dispatch, options)
    ),
    showNotification: (type, message) => dispatch(showNotification(type, message))
})

/** @namespace Component/BundlerSection/Container */
class BundlerSectionContainer extends PureComponent {

    state = {
        totalPrice: null,
        discountedPrice: null,
        currency: "EUR",
        allProducts: [],
        selectedItem: null,
        isLoading: false,
        selectedItems: null,
        selectedOption: null,
        mainProductValue: 0
    }

    static defaultProps = {
        quantity: 1,
        configurableVariantIndex: 0,
        setQuantityToDefault: () => { },
        onProductValidationError: () => { },
        isLoading: false
    };

    containerFunctions = {
        buttonClick: this.buttonClick.bind(this),
        selectItem: this.selectItem.bind(this),
        hasBundlerItems: this.hasBundlerItems.bind(this),
        updateBundlerItems: this.updateBundlerItems.bind(this)
    };

    containerProps() {
        const {
            bundlerItems,
            configurableProduct
        } = this.props;

        return {
            bundlerItems,
            configurableProduct
        }
    }

    selectItem(item) {
        alert("sasa");
        this.setState({
            selectedItem: item
        })
    }

    componentDidMount() {
        const {
            mainProduct
        } = this.props;

        const { price_range: { minimum_price: { final_price: { value: mainProductValue, currency } } } } = mainProduct;

        return this.setState({
            currency,
            mainProductValue,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            mainProduct
        } = this.props;

        const {
            mainProduct: prevProduct
        } = prevProps;

        const { price_range: { minimum_price: { final_price: { value: mainProductValue, currency } } } } = mainProduct;

        if (prevState.mainProductValue !== mainProductValue || prevProduct.sku !== mainProduct.sku) {
            return this.setState({
                currency,
                mainProductValue,
            })
        }
    }

    validationMap = {
        [CONFIGURABLE]: this.validateConfigurableProduct.bind(this),
        [GROUPED]: this.validateGroupedProduct.bind(this),
        [BUNDLE]: this.validateBundleProduct.bind(this)
    };

    addToCartHandlerMap = {
        [CONFIGURABLE]: this.addConfigurableProductToCart.bind(this),
        [GROUPED]: this.addGroupedProductToCart.bind(this)
    };

    validateConfigurableProduct(product) {
        const { variants = [] } = product;
        const {
            configurableVariantIndex,
            showNotification
        } = this.props;

        if (configurableVariantIndex < 0 || !variants[configurableVariantIndex]) {
            showNotification('info', __('Please select product options!'));
            return false;
        }

        const { stock_status: configurableStock } = variants[configurableVariantIndex];

        if (configurableStock !== 'IN_STOCK') {
            showNotification('info', __('Sorry! The selected product option is out of stock!'));
            return false;
        }

        return true;
    }

    validateGroupedProduct(product) {
        const { items } = product;
        const {
            groupedProductQuantity,
            showNotification
        } = this.props;

        const isAllItemsAvailable = items.every(({ product: { id } }) => groupedProductQuantity[id]);

        if (!isAllItemsAvailable) {
            showNotification('info', __('Sorry! Child product quantities are invalid!'));
            return false;
        }

        return true;
    }

    validateBundleProduct(product) {
        const {
            productOptionsData,
            showNotification
        } = this.props;

        const validateBundleOptions = this.validateCustomizableOptions(productOptionsData, true);

        if (!validateBundleOptions) {
            showNotification('info', __('Please select required option!'));
            return false;
        }

        return true;
    }

    validateSimpleProduct(product) {
        const {
            productOptionsData,
            showNotification
        } = this.props;

        const validateCustomizableOptions = this.validateCustomizableOptions(productOptionsData);

        if (!validateCustomizableOptions) {
            showNotification('info', __('Please select required option!'));
            return false;
        }

        return true;
    }

    validateCustomizableOptions(productOptionsData, isBundle = false) {
        const {
            requiredOptions = {}
        } = productOptionsData || {};

        if (requiredOptions.length) {
            const {
                productOptions,
                productOptionsMulti,
                requiredOptions
            } = productOptionsData;

            return this.validateProductOptions(
                [...productOptions || [], ...productOptionsMulti || []],
                requiredOptions,
                isBundle
            );
        }

        return true;
    }

    validateProductOptions(items, requiredOptions, isBundle = false) {
        // Make sure EVERY required option is FOUND in selected items
        return requiredOptions.every((requiredOption) => (
            items.find((item) => {
                const { id, option_id } = item;
                const matchWith = isBundle ? id : option_id;
                return requiredOption === matchWith;
            })
        ));
    }

    addGroupedProductToCart(product, bundler_skus) {
        const { items } = product;
        const {
            groupedProductQuantity,
            addProduct
        } = this.props;

        Promise.all(items.map((item) => {
            const { product: groupedProductItem } = item;

            const newProduct = {
                ...groupedProductItem,
                parent: product
            };

            const quantity = groupedProductQuantity[groupedProductItem.id];

            if (!quantity) {
                return Promise.resolve();
            }

            return addProduct({
                product: newProduct,
                quantity,
                bundler_skus
            });
        })).then(
            /** @namespace Component/AddToCart/Container/addGroupedProductToCartPromiseAllThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addGroupedProductToCartPromiseAllCatch */
            () => this.resetLoading()
        );
    }
    addConfigurableProductToCart(product, bundler_skus) {
        const {
            quantity = 1,
            addProduct,
            configurableVariantIndex,
            productOptionsData
        } = this.props;

        addProduct({
            product: {
                ...product,
                configurableVariantIndex
            },
            quantity,
            productOptionsData,
            bundler_skus
        }).then(
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addConfigurableProductToCartAddProductCatch */
            () => this.resetLoading()
        );
    }

    resetLoading() {
        this.setState({ isLoading: false });
    }

    afterAddToCart() {
        const {
            showNotification,
            setQuantityToDefault
        } = this.props;

        showNotification('success', __('Product added to cart!'));
        this.setState({ isLoading: false });
    }

    addSimpleProductToCart(product, bundler_skus) {
        const {
            quantity,
            addProduct,
            productOptionsData
        } = this.props;

        addProduct({
            product,
            quantity,
            productOptionsData,
            bundler_skus
        }).then(
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
            () => this.afterAddToCart(),
            /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
            // () => this.resetLoading()
            (error) => console.log(error)
        );
    }

    addProductToCart(product, bundler_skus) {
        const {
            type_id
        } = product;

        const addToCartHandler = this.addToCartHandlerMap[type_id];

        if (addToCartHandler) {
            addToCartHandler(product, bundler_skus);
            return;
        }

        this.addSimpleProductToCart(product, bundler_skus);
    }

    validateAddToCart(product) {
        const { type_id } = product;

        const validationRule = this.validationMap[type_id];

        if (validationRule) {
            return validationRule(product);
        }

        return this.validateSimpleProduct(product);
    }

    getVariantsOption(indexedItem, item) {
        const { configurable_options = {} } = indexedItem;
        const { attributes = {} } = item;
        const itemsConfigurations = Object.keys(configurable_options).reduce((acc, key) => {
            return [
                ...acc,
                {
                    option_id: attributes[key].attribute_id,
                    option_value: attributes[key].attribute_value
                }
            ]
        }, [])
        return itemsConfigurations;
    }

    hasBundlerItems(detailsLoaded) {
        const {
            bundlerItems,
            mainProduct,
            configurableProduct
        } = this.props;

        if (detailsLoaded && bundlerItems && bundlerItems.items) {
            if (configurableProduct.type_id === CONFIGURABLE) {
                if (mainProduct.product_links && mainProduct.product_links.length > 0) {
                    return true
                }
                if (configurableProduct.product_links && configurableProduct.product_links.length > 0) {
                    return true
                }
            }
            else {
                return true;
            }
        }
        return false;
    }

    updateBundlerItems(detailsLoaded) {
        const { bundlerItems, mainProduct, configurableProduct } = this.props;

        let customBundlerItems = [];

        if (detailsLoaded && bundlerItems && bundlerItems.items) {
            if (configurableProduct.type_id === CONFIGURABLE) {
                // configurables product links
                if (
                    configurableProduct.product_links &&
                    configurableProduct.product_links.length > 0
                ) {
                    // "config has bundler links select configurables product links"
                    customBundlerItems = configurableProduct.product_links.filter(
                        (link) => !bundlerItems.items.some((item) => link.id === item.id)
                    );
                }


                // simple of configurable has extra or only product links
                if (mainProduct.product_links && mainProduct.product_links.length > 0) {
                    // "simple of config has bundler links combine mains links with configurables links"
                    const temp = mainProduct.product_links.filter(
                        (link) => !bundlerItems.items.some((item) => link.id === item.id)
                    );
                    customBundlerItems = [...customBundlerItems, ...temp];
                }
            }
            // simple has bundler links
            else {
                customBundlerItems = bundlerItems;
            }
        }
        if (!Array.isArray(customBundlerItems) && Object.keys(customBundlerItems).length > 1)
            return customBundlerItems;
        const updatedList = bundlerItems.items.reduce((acc, item) => {
            const selectItem = customBundlerItems.filter(link =>
            (link.linked_product_sku === item.linked_product_sku &&
                link.discount === item.discount &&
                link.discount_type === item.discount_type)
            )
            if (selectItem && selectItem.length > 0)
                return [...acc, item];
            return acc;
        }, []);
        return {
            total_count: customBundlerItems.length,
            items: updatedList
        }
    }

    buttonClick(e, selectItem = {}, selectedConfiguration = {}) {
        const {
            onProductValidationError,
            mainProduct,
            configurableProduct
        } = this.props;
        const { type_id } = mainProduct;
        const { type_id: original_type_id } = configurableProduct;
        let bundler_skus = [];

        if (type_id === SIMPLE && original_type_id !== CONFIGURABLE) {
            if (!this.validateAddToCart(mainProduct)) {
                onProductValidationError(type_id);

                return;
            }

            switch (selectItem.type_id) {
                case CONFIGURABLE: {
                    const selectedVariant = selectItem.variants.find(variant =>
                        selectedConfiguration && selectedConfiguration.sku ?
                            variant.sku === selectedConfiguration.sku
                            : variant.sku === selectItem.linked_product_sku
                    ) || {}
                    const configurableOptions = this.getVariantsOption(selectItem, selectedVariant)

                    if (configurableOptions) {
                        bundler_skus = [{
                            sku: selectItem.sku,
                            product_type: selectItem.type_id,
                            quantity: 1,
                            product_option: {
                                extension_attributes: {
                                    configurable_item_options: configurableOptions
                                }
                            }
                        }];
                    }

                    e.preventDefault();
                    this.setState({ isLoading: true }, () => this.addProductToCart(mainProduct, bundler_skus));
                    break;
                }
                default: {
                    bundler_skus = [{
                        sku: selectItem.sku,
                        product_type: "simple",
                        quantity: 1,
                        product_option: { extension_attributes: {} }
                    }];

                    e.preventDefault();
                    this.setState({ isLoading: true }, () => this.addProductToCart(mainProduct, bundler_skus));
                    break;
                }
            }
        }
        if (type_id === SIMPLE && original_type_id === CONFIGURABLE) {
            if (!this.validateAddToCart(configurableProduct)) {
                onProductValidationError(type_id);

                return;
            }
            switch (selectItem.type_id) {
                case CONFIGURABLE: {
                    const selectedVariant = selectItem.variants.find(variant =>
                        selectedConfiguration && selectedConfiguration.sku ?
                            variant.sku === selectedConfiguration.sku
                            : variant.sku === selectItem.linked_product_sku
                    ) || {}
                    const configurableOptions = this.getVariantsOption(selectItem, selectedVariant)

                    if (configurableOptions) {
                        bundler_skus = [{
                            sku: selectItem.sku,
                            product_type: selectItem.type_id,
                            quantity: 1,
                            product_option: {
                                extension_attributes: {
                                    configurable_item_options: configurableOptions
                                }
                            }
                        }];
                    }

                    e.preventDefault();
                    this.setState({ isLoading: true }, () => this.addProductToCart(configurableProduct, bundler_skus));
                    break;
                }
                default: {
                    bundler_skus = [{
                        sku: selectItem.sku,
                        product_type: "simple",
                        quantity: 1,
                        product_option: { extension_attributes: {} }
                    }];

                    e.preventDefault();
                    this.setState({ isLoading: true }, () => this.addProductToCart(configurableProduct, bundler_skus));
                    break;
                }
            }
        }
    }

    render() {
        return (
            <BundlerSectionComponent
                {...this.props}
                {...this.state}
                {...this.containerProps()}
                {...this.containerFunctions}
            />
        );
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(BundlerSectionContainer);
