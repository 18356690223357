import { DEFAULT_STATE_NAME } from 'SourceComponent/NavigationAbstract/NavigationAbstract.config';
import { isHomePageUrl } from 'SourceUtil/Url';
import { removeRichData } from '../store/RichData/RichData.action';


export const RichDataDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers", webpackPreload: true  */
    '../store/RichData/RichData.dispatcher'
);

export class HeaderContainerPlugin {


    mapDispatchToProps = (args, callback, instance) => {
        const [dispatch] = args;
        return {
            ...callback.apply(instance, args),
            addRichData: (options) => RichDataDispatcher.then(
                ({ default: dispatcher }) => dispatcher.addRichdata(options, dispatch)),
            removeRichdata: (type, isExist) => dispatch(removeRichData(type, isExist)),
        };
    };

    isExist = () => {
        if (document.getElementById(DEFAULT_STATE_NAME)) {
            return true;
        }
        return false;
    };

    mountRichData = (instance) => {
        const { addRichData, header_logo_src } = instance.props;

        const options = {
            name: DEFAULT_STATE_NAME,
            isExist: this.isExist(),
            logo: header_logo_src
        };
        addRichData(options);
    };

    componentDidMount = (args, callback, instance) => {
        const { pathname } = location;

        if (isHomePageUrl(pathname) && !this.isExist()) {
            this.mountRichData(instance);
        }

        callback.apply(instance, args);
    }

    componentDidUpdate = (args, callback, instance) => {
        const { pathname } = location;

        if (isHomePageUrl(pathname) && !this.isExist()) {
            this.mountRichData(instance);
        }

        if (this.isExist() && !isHomePageUrl(pathname)) {
            this.unMountRichData(instance)
        }

        callback.apply(instance, args);
    }

    unMountRichData = (instance) => {
        const { removeRichdata } = instance.props;
        removeRichdata(
            DEFAULT_STATE_NAME,
            this.isExist(),
        );
    }
}

export const { componentDidUpdate, componentDidMount, mapDispatchToProps } = new HeaderContainerPlugin();

export default {
    'Component/Header/Container/mapDispatchToProps': {
        function: [
            {
                position: 100,
                implementation: mapDispatchToProps
            }
        ]
    },
    'Component/Header/Container': {
        'member-function': {
            componentDidUpdate: [
                {
                    position: 100,
                    implementation: componentDidUpdate
                }
            ],
            componentDidMount: [
                {
                    position: 100,
                    implementation: componentDidMount
                }
            ]
        }
    }
};
