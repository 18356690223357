import { capitalizeFirstLetter } from '@mageguide/admin-customer/src/utils';
import { roundPrice } from 'SourceUtil/Price';
import { CONFIGURABLE, SIMPLE } from 'SourceUtil/Product/Types';

import {
    CATEOGRY_LIMIT, CHECKOUT, GROUPED, NOT_APPLICABLE, ZERO
} from '../component/GoogleTagManager/GoogleTagManager.config';
import { getIndexedAttributes } from 'SourceUtil/Product';

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductName */
export const getProductName = (type, product, mainSku = '', click) => {
    const { name = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === SIMPLE) {
        return name;
    }

    if (type === CONFIGURABLE && mainSku) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku)?.name;
    }

    return name;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getProductSKU */
export const getProductSKU = (type, product, mainSku = '', click) => {
    const { sku = '' } = product;
    if (click) {
        return NOT_APPLICABLE;
    }

    if (type === SIMPLE) {
        return sku;
    }

    if (type === CONFIGURABLE && mainSku) {
        const { variants } = product;
        return variants.find(({ sku }) => sku === mainSku).sku;
    }

    return sku;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getCategories */
export const getCategories = (categories = []) => {
    if (!Array.isArray(categories)) {
        return [];
    }

    const limitedCategories = categories.slice(0, CATEOGRY_LIMIT).map(({ name }) => name);
    const categoryString = limitedCategories.join('/');
    return categoryString.charAt(0) === '/' ? categoryString.substring(1) : categoryString;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getQty */
export const getQty = (qty) => {
    if (qty) {
        return { quantity: qty };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getVariantIndex */
export const getVariantIndex = (type_id, variants, configurableVariantIndex) => {
    if (type_id === SIMPLE) {
        return -1;
    } if (!isNaN(configurableVariantIndex) && configurableVariantIndex >= 0 && configurableVariantIndex !== false) {
        return configurableVariantIndex;
    }
    if (variants[0] !== undefined) {
        return 0;
    }

    return -1;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPrice */
export const getPrice = (variant, type_id, price, notInGetProduct = true) => {
    if (price && notInGetProduct || !variant?.price_range?.minimum_price) {
        return price;
    }

    const {
        price_range: {
            minimum_price: {
                final_price: {
                    value: discountValue = null
                } = {},
                regular_price: {
                    value = 0
                } = {}
            }
        } = {}
    } = variant;

    return type_id !== GROUPED
        ? +roundPrice(discountValue || value) || 0
        : 0;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getDiscountPercentage */
export const getDiscountPercentage = (variant = {}, type_id, price, notInGetProduct = true) => {
    if (variant && !Object.values(variant)?.length > 0) {
        return 0;
    }

    const {
        price_range: {
            minimum_price: {
                discount: {
                    percent_off: discountPercentage = 0
                } = {},
            } = {}
        } = {}
    } = variant;

    return type_id !== GROUPED
        ? discountPercentage || 0
        : 0;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getStockStatus */
export const getStockStatus = (product, type_id, variants) => {

    if (!variants?.lenght || variants?.lenght == 0 || !variants) {
        return product?.stock_item.in_stock ? 'IN_STOCK' : product?.stock_status;
    }

    const {
      stock_status,
      stock_item
    } = variants[0];

    return stock_item?.in_stock ? 'IN_STOCK' :stock_status;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getBrand */
export const getBrand = (product = {} ) => {

    if(product?.attributes && product?.attributes[0]) {
        let { attributes = {} } = product;
        attributes = getIndexedAttributes(attributes);

        if(!attributes?.brand) {
            return '';
        }

        const {  brand: manufacturer } = attributes;
        
        const {
            attribute_options,
            attribute_value

        } = manufacturer;

        if (!Object.keys(attribute_options).length > 0 || !attribute_options[attribute_value]?.label) {
            return '';
        }

        const { label } = attribute_options[attribute_value];

        return label;
    }

    if(!product?.attributes?.brand) {
        return '';
    }

    const {
        attributes: {
            brand: manufacturer
        } = {}
    } = product;

    if (manufacturer === undefined) {
        return NOT_APPLICABLE;
    }

    const {
        attribute_options,
        attribute_value
    } = manufacturer;

    if (!Object.keys(attribute_options).length > 0 || !attribute_options[attribute_value]?.label) {
        return '';
    }

    const { label } = attribute_options[attribute_value];
    return label;
};

export const getCustomeAttribuite = (product = {}, name) => {

    if(name == 'mg_custom_lists') {
        const mg_custom_lists = product?.mg_custom_lists || [];

        const labelsArray = mg_custom_lists.filter(item => item.label).map(item => item.name);
        const labelsString = labelsArray.join("/");

        return labelsString;
    }

    if(product?.attributes) {
        const attributes = product.attributes;
    
        if (!attributes[name]) {
            return '';
        }

        const {
            attribute_options,
            attribute_value
        }= attributes[name];

        if(name == "badge_newin" && attribute_value === '0') {
            return 'new';
        }


        if (!Object.keys(attribute_options).length > 0 || 
            !attribute_options[attribute_value]?.label) {

            return '';
        }

        const { label } = attribute_options[attribute_value];

        return label;
    }
    const {
        attributes: {
            [name]: custom
        } = {}
    } = product;    

    if (custom === undefined) {
        return '';
    }

    const {
        attribute_options,
        attribute_value
    } = custom;

    if (!Object.keys(attribute_options).length > 0 || !attribute_options[attribute_value]?.label) {
        return '';
    }

    const { label } = attribute_options[attribute_value];
    return label;
};


/** @namespace GtmNew/EventData/BaseProduct/Data/getVariant */
export const getVariant = (product, type_id, configurableVariantIndex = false) => {
    const {
        variants,
        configurable_options
    } = product;


    if(type_id === SIMPLE ||  !variants) {
        return '';
    }

    if (type_id === SIMPLE || !variants || variants[0] === undefined) {
        const key = Object.keys(configurable_options)[0];
        
        return configurable_options[key]?.attribute_label || NOT_APPLICABLE;
    }
    const key = Object.keys(configurable_options)[0];

    if(!key) {
        return '';
    }

    const name = configurable_options[key]?.attribute_code;
    
    if (product?.attributes && product?.attributes[0]) {
        const attributes = getIndexedAttributes(product?.attributes);

        if (!attributes[name]) {
            return '';
        }

        const {
            attribute_options,
            attribute_value
        } = attributes[name];

        if (!Object.keys(attribute_options).length > 0 || !attribute_options[attribute_value]?.label) {
            return '';
        }

        const { value = '', label = '' } = attribute_options[attribute_value];

        return value || '';
    }

    const {         
        attributes: {
            [name]: codeName
        } = {}
    } = product;

    const configIndex = !configurableVariantIndex || (variants[0] && variants.length == 1 )? 0 : configurableVariantIndex;

    if(!codeName || !codeName?.attribute_options || !variants[configIndex]) {
        return ''
    }

    const {
        attribute_options
    } = codeName;
    
    const {
        attributes: {
            [name]: {
                attribute_value = ''
            } = {}
        } = {}
    } = variants[configIndex];
    
    if(attribute_options[attribute_value]?.value || attribute_options[attribute_value]?.value) {
        const { value, label } = attribute_options[attribute_value];
    
        return value || '';
    }

    return ''
};

/** @namespace GtmNew/EventData/BaseProduct/Data/getPosition */
export const getPosition = (position, click) => {
    if (click && position) {
        return { position };
    }

    return null;
};

/** @namespace GtmNew/EventData/BaseProduct/Data/baseProductData */
export const baseProductData = (item, click = false, notInGetProduct = true, event = '') => {
    const {
        qty = false, price, sku: mainSku = '', 
        product: {
            type_id = SIMPLE, 
            categories, 
            sku: parentSku, 
            position = 0, 
            name: parentName, 
            variants = []
        } = {}, 
        product
    } = item;

    const data = {
        id: parentSku,
        name: parentName,
        title: parentName,
        item_price: (type_id === CONFIGURABLE && notInGetProduct === false)
            ? getPrice(variants[0], type_id, price, notInGetProduct)
            : getPrice(product, type_id, price, notInGetProduct),
        price: (type_id === CONFIGURABLE && notInGetProduct === false)
            ? getPrice(variants[0], type_id, price, notInGetProduct)
            : getPrice(product, type_id, price, notInGetProduct),
        ...getQty(qty),
        ...getPosition(position, click),
        category: getCategories(categories),
        brand: getBrand(product),
        google_business_vertical: 'retail',
        dimension3: getCustomeAttribuite(product, 'att_108'),
        dimension4: getCustomeAttribuite(product, 'badge_newin') || '',
        dimension2: getStockStatus(product, type_id, variants),
        dimension5: (type_id === CONFIGURABLE && notInGetProduct === false)
        ? getDiscountPercentage(variants[0], type_id, price, notInGetProduct)
        : getDiscountPercentage(product, type_id, price, notInGetProduct),
        dimension6: getCustomeAttribuite(product, 'mg_custom_lists')
        // dimension1: (type_id === CONFIGURABLE && !click) ? mainSku : NOT_APPLICABLE,
        // dimension2: type_id === CONFIGURABLE ? getProductName(type_id, product, mainSku, click) : NOT_APPLICABLE
    };

    if (event === CHECKOUT || type_id === CONFIGURABLE) {
        data.variant = getVariant(product, type_id, ZERO);
    }
    
    return data;
};
